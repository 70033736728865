///////////////////////////////////////////////////////////// PRE
import packageJson from '../../../../package.json';

export const environment = {
  brand: 'boomerang',
  production: false,
  domain: 'https://testagencias.boomerangfly.es',
  domainStatics: 'https://testwebapi.boomerangfly.es',
  apidomain: 'https://testwebapi.boomerangfly.es/api/',
  googlekey: 'AIzaSyD-iCufdnhQrQ_YiY4yZYS54s-XNqQ1ZPo',
  youtubeList: 'PLHxbayyiZoxbtHs78f9gnKndqpo6haDm5',
  recaptchakey: '6LdzOdIUAAAAADyseIztq8KvrhALnCO782v8cCPI',
  googleSiteVerification: 'O5oqZ7G2u3Wt0-I2mOi42_-rcTqyk_lDoE4d9Tthh2k',
  chat: {
    api: 'https://boomerang.socket.navisk.com/chat/api', // 'https://boomerang.socketdev.navisk.com/chat/api',
    socket: 'https://boomerang.socket.navisk.com/', // 'https://boomerang.socketdev.navisk.com/'
  },
  defaultLang: 'es',
  myMessages: true,
  allowedVideo: true,
  allowedManagerUsers: true,
  version: packageJson.version
};
